module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fine Lines Construction","short_name":"FLC","start_url":"/","background_color":"#133546","theme_color":"#133546","display":"minimal-ui","icon":"src/assets/images/flc-site-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-69711269-3","head":true,"exclude":["https://develop--flc-website.netlify.com/**"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
