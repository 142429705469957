import { createMuiTheme } from '@material-ui/core/styles'

const workSans = ['Work Sans', 'sans-serif'].join(',')
const FLC_COLOR_BLUE = '#133546'
const FLC_COLOR_ORANGE = '#F15636'

const theme = createMuiTheme({
    palette: {
        background: {
            default: FLC_COLOR_BLUE,
        },
        text: {
            primary: '#ffffff',
            grey: '#cccccc',
            blue: FLC_COLOR_BLUE,
        },
        primary: {
            // light: will be calculated from palette.primary.main,
            main: FLC_COLOR_BLUE, // flc blue
            rgb: '19,53,70', // flc blue - rgb
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: FLC_COLOR_ORANGE, // flc orange
            rgb: '241,86,54', // flc orange - rgb
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        // error: will use the default color
    },
    typography: {
        fontFamily: workSans,
        fontWeightLight: 200,
        fontWeightRegular: 300,
        fontWeightMedium: 400,
    },
})

// Typography overides
theme.typography = {
    ...theme.typography,
    h1: {
        ...theme.typography.h1,
        fontSize: '2.25rem',
        fontWeight: theme.typography.fontWeightRegular,
        margin: '2rem 0 1.25rem 0',
        lineHeight: '1.2em',
    },
    h2: {
        ...theme.typography.h2,
        fontSize: '2rem',
        fontWeight: theme.typography.fontWeightRegular,
        margin: '2rem 0 1.25rem 0',
        lineHeight: '1.2em',
    },
    h3: {
        ...theme.typography.h3,
        fontSize: '1.75rem',
        fontWeight: theme.typography.fontWeightRegular,
        margin: '2rem 0 1.25rem 0',
        lineHeight: '1.2em',
    },
    h4: {
        ...theme.typography.h4,
        fontSize: '1.5rem',
        fontWeight: theme.typography.fontWeightRegular,
        margin: '2rem 0 1.25rem 0',
        lineHeight: '1.2em',
    },
    h5: {
        ...theme.typography.h5,
        fontSize: '1.25rem',
        fontWeight: theme.typography.fontWeightRegular,
        margin: '2rem 0 1.25rem 0',
        lineHeight: '1.2em',
    },
    h6: {
        ...theme.typography.h6,
        fontSize: '1rem',
        fontWeight: theme.typography.fontWeightRegular,
        margin: '2rem 0 1.25rem 0',
        lineHeight: '1.2em',
    },
    body1: {
        ...theme.typography.body1,
        fontSize: theme.typography.pxToRem(16),
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(18),
        },
    },
}

// Component Overrides
theme.overrides = {
    // Forms
    MuiInputBase: {
        input: {
            '&:-webkit-autofill': {
                transitionDelay: '9999s',
                transitionProperty: 'background-color, color',
            },
        },
    },
    MuiInput: {
        underline: {
            '&&:before, &&&&:hover:before': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
            },
            '&:after': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.25)',
            },
        },
    },
    MuiInputLabel: {
        root: {
            color: theme.palette.text.primary,
            fontSize: theme.typography.pxToRem(16),
            '&$shrink': {
                color: theme.palette.text.grey,
            },
        },
    },
    MuiSelect: {
        select: {
            '&:focus': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            color: theme.palette.text.grey,
        },
    },
    MuiPopover: {
        paper: {
            backgroundColor: theme.palette.primary.light,
        },
    },
}

export default theme
