import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import theme from './styles/theme'

function TopLayout(props) {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta
          name="google-site-verification"
          content="4Cm1LFwEiUMCpn3RCH07uzTmrF_WNszg8BUJJ1ssFiQ"
        />
        <html lang="en" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Work+Sans:200,300,400"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </>
  )
}

TopLayout.propTypes = {
  children: PropTypes.node,
}

export default TopLayout
